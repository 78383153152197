<template>
  <div class="container mx-auto mt-5 mb-20">
     <button @click="showModal = true"> Click me {{ $lodash.ago("Thu Jul 14 2022 17:41:25 GMT+0600 (Bangladesh Standard Time)") }}</button>
    <!-- <div class="p-6">
      <h2 @click="showModal = true">
        I am date : {{ $lodash.ago("2022/01/02") }}
      </h2>
    </div>

    <TextComponent text="01" font-size="40" color="#2b80ad" classes="fw-600" />
    <TextComponent
      text="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
      color="#bbbbbb"
      classes="fw-400 fs-10"
    />
    <TextComponent
      text="sample text"
      font-size="20px"
      color="#3d3d3d"
      classes="fw-600 capitalize"
    /> -->

    <hr class="my-5" />

    <!-- <RoundImage path="/images/avatar.jpg" height="100" width="100" />
    <RoundImage path="/images/avatar.jpg" height="100" width="100" />

    <hr class="my-5" />

    <HorizontalBar />
    <ParameterValue />

    <hr class="my-5" />
    <Agenda />
    <hr class="my-5" />
    <TitleContent />
    <hr class="my-5" />
    <div class="bg-white p-10">
      <CoverPageTemplate />
    </div>
    <hr class="my-5" />
    <LogicBuilderTwo />
    <hr class="my-5" />
    <CustomComponent />
    <hr class="my-5" />
    <CircularMenu />
    <hr class="my-5" />
    <LogicBuilderOne />
    <hr class="my-5" /> -->

    <Modal v-model="showModal">
      <h2>Hi I am from modal</h2>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/elements/atom/Modal";
// import TextComponent from "@/elements/atom/TextComponent.vue";
// import RoundImage from "@/elements/atom/RoundImage.vue";
// import HorizontalBar from "@/elements/atom/HorizontalBar.vue";
// import ParameterValue from "@/elements/molecule/ParameterValue.vue";
// import Agenda from "@/elements/molecule/Agenda.vue";
// import CoverPageTemplate from "@/elements/molecule/CoverPageTemplate.vue";
// import LogicBuilderTwo from "@/elements/molecule/LogicBuilderTwo.vue";
// import LogicBuilderOne from "@/elements/molecule/LogicBuilderOne.vue";
// import CustomComponent from "@/elements/molecule/CustomComponent.vue";
// import CircularMenu from "@/elements/molecule/CircularMenu.vue";

import { formatDistance, formatDistanceToNow } from "date-fns";

export default {
  name: "Components",
  components: {
    Modal,
    // TextComponent,
    // RoundImage,
    // HorizontalBar,
    // ParameterValue,
    // Agenda,
    // TitleContent,
    // CoverPageTemplate,
    // LogicBuilderTwo,
    // CustomComponent,
    // CircularMenu,
    // LogicBuilderOne,
  },
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    format: (...arg) => formatDistanceToNow(...arg),
  },
};
</script>
